import { Box, Typography } from '@mui/material';
import { Fragment, useMemo } from 'react';
import { LineItemModel, OrderModel } from 'model/Entities';

import Barcode from 'react-barcode';
import Watermark from './Watermark';
import { checkIfOutOfStock } from 'utils';

type Props = {
  orders: OrderModel[];
  batchNumber: number;
  skuLocations: any;
};

const CombinePickList: React.FC<Props> = ({ orders, batchNumber, skuLocations }) => {
  const allLineItems = useMemo(() => {
    return orders.flatMap((order) => {
      order.line_items.map((line_item) => (line_item.dear_errors = order.dear_errors));
      return order.line_items;
    });
  }, [orders]);

  const combinedLineItems: LineItemModel[] = useMemo(() => {
    return Object.values(
      allLineItems.reduce((result: any, line_item) => {
        let key = line_item.sku;
        const oos = checkIfOutOfStock(line_item.dear_errors ?? [], line_item.sku);
        if (!result[key]) {
          result[key] = JSON.parse(JSON.stringify(line_item));
          if (oos) {
            result[key].oos_qty = line_item.quantity;
            result[key].quantity = 0;
          }
        } else {
          if (oos) {
            result[key].oos_qty = (result[key].oos_qty ?? 0) + line_item.quantity;
          } else {
            result[key].quantity += line_item.quantity;
          }
        }
        return result;
      }, {})
    );
  }, [allLineItems]);

  const totalQuantity = useMemo(
    () =>
      combinedLineItems.reduce((total, item) => {
        return total + item.quantity;
      }, 0),
    [combinedLineItems]
  );

  const sortedLineItems = useMemo(() => {
    combinedLineItems.forEach((item) => (item.stock_locator = skuLocations[item.sku]?.stock_locator ?? '-'));
    combinedLineItems.sort((a, b) => {
      const aLocation = a.stock_locator.replace(/\[|\]/g, '');
      const bLocation = b.stock_locator.replace(/\[|\]/g, '');
      if (aLocation === '-') return 1;
      if (bLocation === '-') return -1;
      if (aLocation < bLocation) return -1;
      if (aLocation > bLocation) return 1;
      return 0;
    });
    return combinedLineItems;
  }, [combinedLineItems, skuLocations]);

  return (
    <Box position="relative">
      <style>{styles}</style>
      <Watermark />
      <Box className="page-break">
        <Box display="flex" width="100%" gap="20px" mb="30px">
          <Box flex="1" sx={{ '& p': { fontSize: '22px', fontWeight: 'bold' } }}>
            <Typography>Batch #: SN-{batchNumber}</Typography>
            <Typography>COMBINE PICK LIST</Typography>
          </Box>
          <Box flex="1">
            <Barcode value={batchNumber + ''} height={70} fontSize={16} />
          </Box>
        </Box>
        <Box
          sx={{
            '& th': { fontSize: '16px', padding: '5px' },
            '& td': { fontSize: '15px', verticalAlign: 'top', padding: '5px' },
            '& tbody td': { borderBottom: '.5px solid #a3a3a3' },
            marginTop: '-120px',
          }}
        >
          <table width="100%" style={{ border: 'none' }} cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th align="left" colSpan={6} style={{ paddingBlock: '60px' }}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th align="left" style={{ width: '10%' }}>
                  Item
                </th>
                <th align="left" style={{ width: '20%' }}>
                  Shopify Order #
                </th>
                <th align="left" style={{ width: '20%' }}>
                  Dear Order #
                </th>
                <th align="left" style={{ width: '20%' }}>
                  Tags
                </th>
                <th align="left" style={{ width: '30%' }}>
                  Customer
                </th>
              </tr>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{order.order_name}</td>
                  <td>{order.dear_order_number}</td>
                  <td>{order.tags}</td>
                  <td>
                    {order.customer?.first_name} {order.customer?.last_name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>

      <Box className="page-break">
        <Box
          sx={{
            '& tbody th': { fontSize: '16px', padding: '5px', background: '#f0f0f0' },
            '& td': { fontSize: '15px', verticalAlign: 'top', padding: '5px' },
            '& tbody td': { borderBottom: '.5px solid #a3a3a3' },
            marginTop: '-120px',
          }}
        >
          <table width="100%" style={{ border: 'none' }} cellPadding={0} cellSpacing={0} className="print-table">
            <thead>
              <tr>
                <th className="header" align="left" colSpan={5}></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th align="left" style={{ width: '10%' }}>
                  #
                </th>
                <th align="left" style={{ width: '8%' }}>
                  SKU
                </th>
                <th align="left" style={{ width: '23%' }}>
                  SKU Loc
                </th>
                <th style={{ width: '10%' }}>Qty</th>
                <th style={{ width: '10%' }}>Unit</th>
                <th style={{ width: '10%' }}>OOS</th>
                <th align="left" style={{ width: '44%' }}>
                  Product Description
                </th>
              </tr>
              {sortedLineItems.map((item, index) => {
                return (
                  <Fragment key={index}>
                    {!!item.quantity && (
                      <tr>
                        <td>
                          {index + 1} {item.oos_qty ? '- A' : ''}
                        </td>
                        <td>{item.sku}</td>
                        <td>{item.stock_locator}</td>
                        <td
                          style={{
                            textAlign: 'center',
                            backgroundColor: item.quantity > 1 ? '#ffff80' : undefined,
                          }}
                        >
                          {item.quantity}
                        </td>
                        <td style={{ textAlign: 'center' }}>Item</td>
                        <td
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          No
                        </td>
                        <td>
                          {item.title} - {item.variant_title}
                        </td>
                      </tr>
                    )}
                    {!!item.oos_qty && (
                      <tr>
                        <td>
                          {index + 1} {item.quantity ? '- B' : ''}
                        </td>
                        <td>{item.sku}</td>
                        <td>{item.stock_locator}</td>
                        <td style={{ textAlign: 'center' }}>{item.oos_qty}</td>
                        <td style={{ textAlign: 'center' }}>Item</td>
                        <td
                          style={{
                            textAlign: 'center',
                            backgroundColor: '#ffff80',
                            color: 'red',
                          }}
                        >
                          Yes
                        </td>
                        <td>
                          {item.title} - {item.variant_title}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td style={{ fontWeight: 'bold' }}>Total</td>
                <td style={{ textAlign: 'center' }}>{totalQuantity}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default CombinePickList;

const styles = `
  @page {
    size: A4;
    margin: 0;
    margin-bottom: 2cm;
  }
    
  .page-break:not(:first-child) {
    page-break-before: always;
  }

  .page-break {
    display: block;
    page-break-inside: avoid;
    padding: 100px 80px;
  }

  .header {
    padding-block: 60px;
  }

  thead th {
    background-color: white;
  }
`;
