import { Box, CircularProgress, List, ListItem, Typography } from '@mui/material';
import { CustomModal, RegularButton } from 'components';
import { resolveOrders, validateOrders } from 'api/batch';
import { useEffect, useState } from 'react';

import { chunkArray } from 'utils';
import { tokens } from 'context/theme.context';

type Props = {
  title: string;
  orders: string[];
  batch_number?: number;
  open: boolean;
  setOpen: (openModal: boolean) => void;
  onClose?: () => void;
};

const OrderValidation: React.FC<Props> = ({ title, orders, batch_number, open, setOpen, onClose }) => {
  const colors = tokens();
  const [validatingOrders, setValidatingOrders] = useState(false);
  const [invalidOrders, setInvalidOrders] = useState<number[]>([]);
  const [validOrders, setValidOrders] = useState<number[]>([]);
  const [errors, setErrors] = useState<any[]>([]);

  const resolveValidOrders = async (validOrders: number[], invalidOrders: number[], errorMessage: any) => {
    if (batch_number) {
      try {
        await resolveOrders({ validOrders, invalidOrders, errorMessage });
      } catch (error) {}
    }
  };

  useEffect(() => {
    const validate = async () => {
      setValidatingOrders(true);
      setInvalidOrders([]);
      setValidOrders([]);
      setErrors([]);
      validateOrders(orders)
        .then(async (res) => {
          if (res.data.invalid_orders.length) {
            setInvalidOrders((prev) => [...prev, ...res.data.invalid_orders]);
          }
          if (res.data.valid_orders.length) {
            setValidOrders((prev) => [...prev, ...res.data.valid_orders]);
          }

          const error_message = res.data.message;
          const message = Object.keys(error_message).map((order_name: any) => ({
            order_name,
            errors: error_message[order_name].map((error: any) => error.Exception),
          }));
          if (message.length) {
            setErrors((prev) => [...prev, ...message]);
          }
          await resolveValidOrders(res.data.valid_orders, res.data.invalid_orders, error_message);
        })
        .finally(() => {
          setValidatingOrders(false);
        });
    };
    if (open && orders.length) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, open]);

  return (
    <CustomModal header={title} open={open} setOpen={setOpen} width={700} onClose={onClose}>
      <Box display="flex" flexDirection="column" gap="20px">
        {validatingOrders ? (
          <Box display="flex" gap="10px">
            <CircularProgress color="inherit" size="1.3rem" />
            <Typography mb="10px">Please wait, validating orders</Typography>
          </Box>
        ) : (
          <>
            <Box display="flex" gap="20px" width="100%">
              <Box flex="1">
                <Typography variant="h4" fontWeight="bold" color={colors.greenAccent}>
                  Total Valid Orders:
                </Typography>
                <Typography fontWeight="bold" fontSize="30px">
                  {validOrders.length}
                </Typography>
              </Box>
              <Box flex="1">
                <Typography variant="h4" fontWeight="bold" color={colors.redAccent}>
                  Total Invalid Orders:
                </Typography>
                <Typography fontWeight="bold" fontSize="30px">
                  {invalidOrders.length}
                </Typography>
              </Box>
            </Box>

            {!!validOrders.length && (
              <Typography>
                The following orders are resolved and removed from this batch: {validOrders.join(', ')}.
              </Typography>
            )}

            {!!invalidOrders.length && (
              <>
                <Box>
                  <Typography>The following orders contain errors: {invalidOrders.join(', ')}.</Typography>

                  <Box mt="20px">
                    {errors.map((order, index) => (
                      <Box>
                        <Typography key={index} fontWeight="bold">
                          Order {order.order_name}
                        </Typography>
                        <List sx={{ paddingLeft: '10px' }}>
                          {order.errors.map((error: any, index2: number) => (
                            <ListItem key={index + '_' + index2} disablePadding>
                              <Typography key={index}>{error}</Typography>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}

            <Box width="100%" display="flex" justifyContent="center">
              <RegularButton
                label="Okay"
                color="info"
                variant="outlined"
                onClick={() => {
                  setOpen(false);
                  onClose && onClose();
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
};

export default OrderValidation;
