import { Box, Divider, Typography } from '@mui/material';
import { LineItemModel, OrderModel } from 'model/Entities';
import { checkIfOutOfStock, formatArray, formatDate } from 'utils';

import { useMemo } from 'react';

type Props = {
  order: OrderModel;
  skuLocations: any;
};

type FieldProps = {
  name: string;
  value?: string | number;
};

const Field: React.FC<FieldProps> = ({ name, value }) => {
  return (
    <>
      <Typography>{name}:</Typography>
      <Typography>{value}</Typography>
    </>
  );
};

const CommercialInvoice: React.FC<Props> = ({ order, skuLocations }) => {
  const shipping_address = order.shopify_data?.shipping_address ?? {};

  const lineItems = useMemo(
    () => order.line_items.filter((item) => !checkIfOutOfStock(order.dear_errors ?? [], item.sku)),
    [order]
  );

  const adjustedPrice: any = useMemo(
    () => ({
      'Care Pack': 0.0,
      Bag: 0.0,
      Accessories: 3.16,
      Bra: 7.98,
      Bras: 7.98,
      Lingerie: 7.98,
      Bralette: 5.98,
      Cheekies: 3.23,
      'Bundle - Cheekies': 9.68,
      'Gift Box - Cheekies': 9.68,
      'Gift Box': 9.68,
      'Gift Box - Empty': 0.99,
      'Lounge Shorts': 4.04,
      'Nylon Shorts': 3.74,
      'Satin Shorts': 4.04,
      Shapewear: 7.48,
      Sleepwear: 5.99,
      Top: 5.78,
      Loungewear: 8.73,
      Others: 2.99,
    }),
    []
  );

  const getAdjustedPrice = (item: LineItemModel) => {
    if (item.sku === '3000219') return 0;
    const productType = skuLocations[item.sku]?.category ?? 'Others';
    const adjusted = adjustedPrice[productType] ?? 2.99;
    const price = parseFloat(item.price);
    const priceToUse = price < adjusted ? price : adjusted;
    return priceToUse;
  };

  const totalValue = useMemo(
    () =>
      lineItems.reduce((total, item) => {
        const priceToUse = getAdjustedPrice(item);
        return total + item.quantity * priceToUse;
      }, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lineItems]
  );

  return (
    <Box>
      <style>{styles}</style>

      <Box className="ci-page-break">
        <Box display="flex" flexDirection="column" gap="20px">
          <Typography fontSize="25px" textAlign="center" fontWeight="bold">
            COMMERCIAL INVOICE
          </Typography>

          <Box display="grid" gridTemplateColumns="1fr 1.5fr 1fr 1.5fr" rowGap="3px" columnGap="10px">
            <Field name="Invoice No." value={order.order_name} />
            <Field name="Origin" value="SIN" />

            <Field name="Invoice Date" value={formatDate(order.order_date)} />
            <Field name="Destination" value={order.shipping_country_code} />

            <Field name="Reason for Export" />
            <Field name="Waybill Number" value={order.tracking_number} />
          </Box>

          <Divider sx={{ borderWidth: '1.2px' }} />
          <Box display="grid" gridTemplateColumns="1fr 1.5fr 1fr 1.5fr">
            <Field name="Waybill Ref" value={order.order_name} />
          </Box>
          <Divider sx={{ borderWidth: '1.2px' }} />

          <Box display="grid" gridTemplateColumns="1fr 1fr">
            <Box display="grid" gridTemplateColumns="1fr 3fr" rowGap="3px" columnGap="10px">
              <Typography gridColumn="span 2" fontWeight="bold" fontSize="20px">
                Sender
              </Typography>

              <Field name="Company" value="NBT Asia" />
              <Field name="Name" value="IMINXX.COM" />
              <Field name="Address" value="514 Chai Chee Lane - #02-15 - Singapore 469029 Singapore" />
              <Field name="Country" value="SG" />
              <Field name="Phone" value="6584981615" />
              <Field name="EORI" value="" />
              <Field name="Tax ID" value="" />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 3fr" rowGap="3px" columnGap="10px">
              <Typography gridColumn="span 2" fontWeight="bold" fontSize="20px">
                Receiver
              </Typography>
              <Field name="Company" value={shipping_address.first_name + ' ' + shipping_address.last_name} />
              <Field name="Name" value={shipping_address.first_name + ' ' + shipping_address.last_name} />
              <Field
                name="Address"
                value={formatArray([
                  shipping_address.address1,
                  shipping_address.address2,
                  shipping_address.zip,
                  shipping_address.city,
                  shipping_address.country,
                ])}
              />
              <Field name="Country" value={shipping_address.country_code} />
              <Field name="Phone" value={shipping_address.phone} />
              <Field name="EORI" value="" />
              <Field name="Tax ID" value="" />
            </Box>
          </Box>

          <Divider sx={{ borderWidth: '1.2px' }} />

          <Box display="grid" gridTemplateColumns="2fr 1fr 2fr 1fr" rowGap="3px" columnGap="10px">
            <Field name="Commodity Description" value="Lingerie" />
            <Field name="Gross Weight" value="0.035 KG" />
            <Field name="Origin of Goods" value="SIN" />
            <Field name="Chargable Weight" value="0.035 KG" />
          </Box>

          <Divider sx={{ borderWidth: '1.2px' }} />

          <Box
            sx={{
              '& th': { fontSize: '16px', padding: '5px', background: '#f0f0f0' },
              '& td': { fontSize: '15px', verticalAlign: 'top', padding: '5px' },
              '& tbody td': { borderBottom: '.5px solid #a3a3a3' },
            }}
          >
            <table width="100%" style={{ border: 'none' }} cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th align="left" style={{ width: '5%' }}>
                    #
                  </th>
                  <th align="left" style={{ width: '15%' }}>
                    SKU
                  </th>
                  <th align="left" style={{ width: '45%' }}>
                    Description
                  </th>
                  <th style={{ width: '5%' }}>Quantity</th>
                  <th align="right" style={{ width: '15%' }}>
                    Unit Price
                  </th>
                  <th align="right" style={{ width: '15%' }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {lineItems.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.sku}</td>
                    <td>
                      {item.title} - {item.variant_title}
                    </td>
                    <td style={{ textAlign: 'center' }}>{item.quantity}</td>
                    <td align="right">{getAdjustedPrice(item)} SGD</td>
                    <td align="right">{item.quantity * getAdjustedPrice(item)} SGD</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={3} style={{ fontWeight: 'bold', textAlign: 'right' }}>
                    Total Price
                  </td>
                  <td align="right">{totalValue.toFixed(2)} SGD</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommercialInvoice;

const styles = `
  @page {
    size: A4;
    margin: 2cm;
  }

  .ci-page-break:not(:first-child) {
    page-break-before: always;
  }

  .ci-page-break {
    display: block;
    page-break-inside: avoid;
  }
`;
