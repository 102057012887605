/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useMemo, useState } from 'react';

import { createTheme } from '@mui/material/styles';

export const tokens = () => ({
  primary: '#fad3d4',
  secondary: '#fc6280',
  accent: '#fc6280',
  blueAccent: '#008ece',
  redAccent: '#db4f4a',
  orangeAccent: '#ec6d03',
  greenAccent: '#228720',
  background: '#fff',
  secondary_background: '#fff2f3',
  text: '#141414',
  white_text: '#e0e0e0',

  dark: '#a3a3a3',
  main: '#666666',
  light: '#141414',
  border: '#e0e0e0',
  hoverBackground: 'rgba(0, 0, 0, 0.04)',
  solidHoverBackground: '#ebf5ef',
  inputBackground: '#f0f0f0',
});

// mui theme settings
export const themeSettings = (mode: string) => {
  // const colors = tokens(mode);
  const colors = tokens();
  return {
    palette: {
      mode: mode,
      // palette values for light mode
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      neutral: {
        dark: colors.dark,
        main: colors.main,
        light: colors.light,
      },
      background: {
        default: colors.background,
      },
    },
    typography: {
      fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
      fontSize: 14,
      h1: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 40,
      },
      h2: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 32,
      },
      h3: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 24,
      },
      h4: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 20,
      },
      h5: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 16,
      },
      h6: {
        fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const theme_mode = localStorage.getItem('theme_mode');
  const [mode, setMode] = useState(theme_mode ?? 'light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        localStorage.setItem('theme_mode', mode === 'light' ? 'dark' : 'light');
        setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode) as any), [mode]);
  return [theme, colorMode];
};
