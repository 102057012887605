/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput, SelectOption } from 'model/interface';
import { ConfirmationDialog, CustomForm, CustomGridCell, CustomModal, PrimaryButton, RowAction } from 'components';
import { UserGroupModel, UserModel } from 'model/Entities';
import { changeNullToBlank, tranformFormErrors } from 'utils';
import { deleteUser, getAccounts, registerUser, updateUser } from 'api/user';
import { update_user_schema, user_schema } from 'model/schema';
import { useContext, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import CustomTable from 'components/CustomTable';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { FormikHelpers } from 'formik';
import { GridColDef } from '@mui/x-data-grid';
import Header from 'components/Header';
import { UserContext } from 'context/user.context';
import { getAllUserGroups } from 'api/user-group';
import { useSnackbar } from 'notistack';

export type UserInput = {
  username: string;
  password: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  user_group_id: string;
  email: string;
  mobile_number: string;
};

export interface UserFieldInput extends FieldInput {
  field_name: keyof UserInput;
}

const initialValues: UserInput = {
  username: '',
  password: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  user_group_id: '',
  email: '',
  mobile_number: '',
};

const initialHiddentFields = {
  username: false,
  password: false,
};

const UserManagement = () => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);

  const [rows, setRows] = useState<UserModel[]>([]);
  const [userCount, setUserCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [userModalHeader, setUserModalHeader] = useState('Create User');
  const [userInitialValues, setUserInitialValues] = useState<UserInput>(initialValues);

  const [userGroupsOptions, setUserGroupsOptions] = useState<SelectOption[]>([]);
  const [hiddenFields, setHiddenFields] = useState(initialHiddentFields);

  const [selectedUser, setSelectedUser] = useState<any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const tableRef: any = useRef();

  // Actions
  const editAction = (data: any) => {
    showUserModal(true, data);
  };

  const deleteAction = (data: any) => {
    setSelectedUser(data);
    setOpenDeleteConfirmation(true);
  };

  const handleDelete = async (user: any) => {
    try {
      await deleteUser(user.id);
      enqueueSnackbar(`User successfully deleted!`, { variant: 'success' });
      tableRef.current.refreshData();
    } catch (error) {
      enqueueSnackbar('Cannot delete user.', { variant: 'error' });
    }
  };

  const actions = [
    {
      label: 'Edit',
      action: editAction,
      startIcon: <DriveFileRenameOutlineIcon />,
    },
    {
      label: 'Delete',
      action: deleteAction,
      startIcon: <DeleteOutlinedIcon />,
    },
  ];
  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'username',
      sortable: false,
      headerName: 'Username',
      flex: 1.5,
    },
    {
      field: 'first_name',
      sortable: false,
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.first_name} {params.row.middle_name} {params.row.last_name}
        </CustomGridCell>
      ),
    },
    {
      field: 'user_group_id',
      sortable: false,
      headerName: 'User Group',
      flex: 2,
      renderCell: (params) => <CustomGridCell>{params.row.user_group.description}</CustomGridCell>,
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        user?.user_group.group_name === 'RMTM' &&
        params.row.user_group.description === 'Regional Monitoring Team Leader' ? (
          <></>
        ) : (
          <RowAction actions={actions} data={params.row} />
        ),
    },
  ];

  // Form Fields
  const userFields: UserFieldInput[] = [
    {
      field_name: 'username',
      display_name: 'Username',
      type: 'text',
      hidden: hiddenFields.username,
    },
    {
      field_name: 'password',
      display_name: 'Password',
      type: 'text',
    },
    {
      field_name: 'first_name',
      display_name: 'First Name',
      type: 'text',
    },
    {
      field_name: 'middle_name',
      display_name: 'Middle Name',
      type: 'text',
    },
    {
      field_name: 'last_name',
      display_name: 'Last Name',
      type: 'text',
    },
    {
      field_name: 'mobile_number',
      display_name: 'Mobile Number',
      type: 'text',
    },
    {
      field_name: 'email',
      display_name: 'Email',
      type: 'email',
    },
    {
      field_name: 'user_group_id',
      display_name: 'User Group',
      type: 'select',
      options: userGroupsOptions,
    },
  ];

  // Functions
  const getUserAccounts = (query?: ApiQuery) => {
    setLoading(true);
    getAccounts({ ...query })
      .then((res) => {
        setRows(res.data.rows);
        setUserCount(res.data.count);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true); //when button submit is clicked turns on the loading animation

    try {
      if (data.id) {
        await updateUser(data.id, data);
      } else {
        await registerUser(data);
      }
      successFormSubmit(false, formikHelpers);
    } catch (error: any) {
      if (error.response?.data) {
        formikHelpers.setErrors(tranformFormErrors(error.response.data));
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const successFormSubmit = (from_edit: boolean, { resetForm, setErrors }: FormikHelpers<any>) => {
    enqueueSnackbar(`User successfully ${from_edit ? 'updated' : 'created'}!`, {
      variant: 'success',
    });

    setOpenUserModal(false);
    resetForm();
    tableRef.current.refreshData();
  };

  const showUserModal = (from_edit?: boolean, data?: UserModel) => {
    if (from_edit && data) {
      const userData: any = changeNullToBlank(data);
      setUserInitialValues(userData);
      setUserModalHeader('Update User');
      setHiddenFields((prev) => ({ ...prev, username: true }));
    } else {
      setUserModalHeader('Create User');
      setUserInitialValues(initialValues);
      setHiddenFields(initialHiddentFields);
    }

    setOpenUserModal(true);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'User Management' }]);
  }, []);

  useEffect(() => {
    if (user) {
      getAllUserGroups().then((res) => {
        const groups = res.data.rows;

        const userGroups = groups.map((userGroup: UserGroupModel) => ({
          value: userGroup.id,
          key: userGroup.description,
        }));

        setUserGroupsOptions(userGroups);
      });
    }
  }, [user]);

  return (
    <>
      <Box>
        <Header title="User Management" />
        <CustomTable
          ref={tableRef}
          columns={columns}
          rows={rows}
          rowCount={userCount}
          loading={loading}
          getData={getUserAccounts}
          searchKeys="Name and Username"
          headerComponent={<PrimaryButton onClick={() => showUserModal()} label="Create User" />}
        />
        <CustomModal header={userModalHeader} open={openUserModal} setOpen={setOpenUserModal}>
          <CustomForm
            initialValues={userInitialValues}
            onSubmit={handleSubmit}
            fields={userFields}
            schema={userModalHeader === 'Update User' ? update_user_schema : user_schema}
            loading={buttonLoading}
          />
        </CustomModal>

        <ConfirmationDialog
          open={openDeleteConfirmation}
          setOpen={setOpenDeleteConfirmation}
          message="Are you sure want to remove this user?"
          onConfirm={async () => await handleDelete(selectedUser)}
        />
      </Box>
    </>
  );
};

export default UserManagement;
