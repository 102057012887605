import { useContext, useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { BreadcrumbContext } from 'context/breadcrumb.context';
import FlagOrderModal from './components/FlagOrderModal';
import { Header } from 'components';
import { ORDERS_TAB } from 'model/Entities';
import OrdersTable from 'screens/Orders/components/OrdersTable';

const StopCheck = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [openFlagOrder, setOpenFlagOrder] = useState(false);
  const stopCheckTableRef: any = useRef();

  useEffect(() => {
    setBreadcrumb([{ label: 'Stop Check' }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Header title="Stop Check" />

      <OrdersTable status={ORDERS_TAB.STOP_CHECK} ref={stopCheckTableRef} />
      <FlagOrderModal
        open={openFlagOrder}
        setOpen={setOpenFlagOrder}
        onFlag={() => {
          stopCheckTableRef.current.refreshTable();
        }}
      />
    </Box>
  );
};

export default StopCheck;
