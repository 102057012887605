import 'react-pro-sidebar/dist/css/styles.css';

import { Box, Tooltip, Typography } from '@mui/material';
import { CustomIconButton, ProtectedComponent } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, ProSidebar } from 'react-pro-sidebar';
import { ReactNode, useEffect, useState } from 'react';

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import { tokens } from 'context/theme.context';

type ItemProps = {
  title: string;
  to: string;
  icon: ReactNode;
  selected: boolean;
  showTooltip: boolean;
};

type MenuItemProps = {
  section_name: string;
  requiredAuth?: string[];
  subitems: SubItemProps[];
};

type SubItemProps = {
  title: string;
  link: string;
  icon: ReactNode;
  requiredAuth?: string[];
  hidden?: boolean;
};

const Item: React.FC<ItemProps> = ({ title, to, icon, selected, showTooltip }) => {
  const colors = tokens();

  return showTooltip ? (
    <Tooltip title={title} placement="right" arrow>
      <MenuItem
        active={selected}
        style={{
          color: colors.text,
        }}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    </Tooltip>
  ) : (
    <MenuItem
      active={selected}
      style={{
        color: colors.text,
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const menu_items: MenuItemProps[] = [
  {
    section_name: '',
    subitems: [
      {
        title: 'Orders',
        link: '/',
        icon: <LocalMallOutlinedIcon />,
      },
      {
        title: 'Batches',
        link: '/batches',
        icon: <ShoppingBasketOutlinedIcon />,
      },
      {
        title: 'Sorting Station',
        link: '/sorting',
        icon: <CategoryOutlinedIcon />,
      },
      {
        title: 'Scanning Station',
        link: '/scanning',
        icon: <CropFreeOutlinedIcon />,
      },
      {
        title: 'Shipping Station',
        link: '/shipping',
        icon: <LocalShippingOutlinedIcon />,
      },
    ],
  },
  {
    section_name: '.',
    subitems: [
      {
        title: 'Stop Check',
        link: '/stop-check',
        icon: <PanToolOutlinedIcon />,
      },
      {
        title: 'Users',
        link: '/user-management',
        icon: <GroupAddOutlinedIcon />,
        requiredAuth: ['MANAGE_USERS'],
      },
    ],
  },
];

const Sidebar = () => {
  const location = useLocation();
  const colors = tokens();
  // const { user } = useContext(UserContext);

  const initialCollapsedState = localStorage.getItem('isCollapsed') === 'true';
  const [isCollapsed, setIsCollapsed] = useState(initialCollapsedState);

  useEffect(() => {
    localStorage.setItem('isCollapsed', isCollapsed.toString());
  }, [isCollapsed]);

  const isTabSelected = (link: string) => {
    return (link === '/' && location.pathname === link) || (link !== '/' && location.pathname.startsWith(link));
  };

  return (
    <Box
      sx={{
        '& .pro-sidebar': {
          minWidth: '240px',
          width: '240px',
        },
        '& .pro-sidebar.collapsed': {
          minWidth: '70px',
          width: '70px',
        },
        '& .pro-sidebar-inner': {
          background: `${colors.secondary_background} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
        },
        '& .pro-inner-item': {
          padding: '5px 35px 5px 20px !important',
        },
        '& .pro-inner-item:hover': {
          color: `${colors.accent} !important`,
        },
        '& .pro-menu-item.active': {
          color: `${colors.accent} !important`,
        },
        '& .pro-menu-item.active p': {
          fontWeight: 'bold',
          letterSpacing: 0.5,
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          {isCollapsed ? (
            <MenuItem
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
              }}
              icon={<MenuOutlinedIcon />}
              style={{
                color: colors.text,
              }}
            />
          ) : (
            <MenuItem
              icon={<img alt="profile-user" width="25px" height="25px" src={`../../assets/imin-logo.webp`} />}
              className="wudi-logo"
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography style={{ color: colors.text, fontWeight: 'bold', letterSpacing: '2px' }}>WUDI</Typography>
                <CustomIconButton
                  icon={<MenuOutlinedIcon />}
                  color={null}
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                    localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
                  }}
                />
              </Box>
            </MenuItem>
          )}

          <Box display="flex" width="100%" justifyContent="center" paddingY="20px">
            <img
              alt="profile-user"
              width={isCollapsed ? '50px' : '100px'}
              height="auto"
              src={`../../assets/imin-logo.webp`}
            />
          </Box>

          <Box>
            {menu_items.map((item, index) => (
              <Box key={index}>
                <Typography>{item.section_name}</Typography>
                <Box>
                  {item.subitems
                    .filter((item) => !item.hidden)
                    .map((subitem) => (
                      <ProtectedComponent requiredAuth={subitem.requiredAuth} key={subitem.link}>
                        <Item
                          title={subitem.title}
                          to={subitem.link}
                          icon={subitem.icon}
                          selected={isTabSelected(subitem.link)}
                          showTooltip={isCollapsed}
                        />
                      </ProtectedComponent>
                    ))}
                </Box>
              </Box>
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
