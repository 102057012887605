import { UserModel } from 'model/Entities';

export const formatDate = (date_str: string) => {
  if (date_str) {
    const date = new Date(date_str);
    const dateOptions: any = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
  }
  return '-';
};

export const formatDateWithDay = (date_str: string, defaultValue?: string) => {
  if (date_str) {
    const date = new Date(date_str);
    const formattedDate = `${date.getDate()} ${date.toLocaleString('en-GB', {
      month: 'short',
    })} ${date.getFullYear()} (${date.toLocaleString('en-GB', { weekday: 'short' })})`;

    return formattedDate;
  }
  return defaultValue ?? '-';
};

export const formatDateTime = (date_str?: string) => {
  if (date_str) {
    const date = new Date(date_str);
    const dateOptions: any = { day: '2-digit', month: 'short', year: 'numeric' };
    const timeOptions: any = { hour: 'numeric', minute: '2-digit', hour12: true };
    const formattedDate = new Intl.DateTimeFormat('en-GB', dateOptions).format(date);
    const formattedTime = date.toLocaleTimeString('en-GB', timeOptions).toLowerCase();

    return `${formattedDate}, ${formattedTime}`;
  }
  return '-';
  // return date ? moment(date).format('lll') : '-';
};

export const tranformFormErrors = (errors: any) => {
  for (var key in errors) {
    errors[key] = errors[key].msg;
  }

  return errors;
};

export const changeNullToBlank: any = (obj: any) => {
  for (var key in obj) {
    if (obj[key] === null) {
      obj[key] = '';
    }
  }

  return obj;
};

export const capitalizeWord = (string: string) => {
  string = string.replaceAll('_', ' ');
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const numberToLetters = (number: number) => {
  let letters = '';
  while (number > 0) {
    number--;
    letters = String.fromCharCode((number % 26) + 65) + letters;
    number = Math.floor(number / 26);
  }
  return letters;
};

export const displayName = (user?: UserModel) => {
  if (user) {
    return [user.title, user.first_name, user.last_name].filter((item) => !!item).join(' ');
  }

  return '-';
};

export const formatNumber = (number: number | string, decimalPlaces?: number) => {
  decimalPlaces = decimalPlaces === undefined ? 2 : decimalPlaces;
  return parseFloat(number + '').toLocaleString('en-US', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
};

export const formatArray = (data: string[], separator?: string) => {
  return data.filter((item) => !!item).join(separator ?? ', ');
};

export const getDateRange = (option: string) => {
  const today = new Date();
  let startDate, endDate;

  switch (option) {
    case 'Today':
    case 'Custom':
      startDate = new Date(today);
      endDate = new Date(today);
      break;
    case 'Yesterday':
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 1);
      endDate = new Date(startDate);
      break;
    case 'Last 7 days':
      endDate = new Date(today);
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 6);
      break;
    case 'Last 30 days':
      endDate = new Date(today);
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 29);
      break;
    case 'Last 90 days':
      endDate = new Date(today);
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 89);
      break;
    case 'Last 12 months':
      endDate = new Date(today);
      startDate = new Date(today);
      startDate.setFullYear(today.getFullYear() - 1);
      startDate.setDate(today.getDate() + 1);
      break;
    case 'Tomorrow':
      startDate = new Date(today);
      startDate.setDate(today.getDate() + 1);
      endDate = new Date(startDate);
      break;
    case 'Next 3 days':
      startDate = new Date(today);
      startDate.setDate(today.getDate() + 1);
      endDate = new Date(today);
      endDate.setDate(today.getDate() + 3);
      break;
    case 'Next 7 days':
      startDate = new Date(today);
      startDate.setDate(today.getDate() + 1);
      endDate = new Date(today);
      endDate.setDate(today.getDate() + 7);
      break;

    default:
      return {
        startDate: '',
        endDate: '',
        value: '',
      };
  }

  // Format dates as YYYY-MM-DD
  const formatDate = (date: any) => date.toISOString().split('T')[0];

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    value: option,
  };
};

export const chunkArray = (arr: any[], chunkSize: number): any[][] => {
  const result: any[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
};

export const getSkuNumber = (message: string) => {
  const skuRegex = /SKU:\s*(\d+)/;
  const match = message.match(skuRegex);
  return match ? match[1] : '';
};

export const checkIfOutOfStock = (dear_errors: any[], sku: string) => {
  return dear_errors.some(
    (error) => error.Exception.startsWith('Insufficient on hand quantity') && getSkuNumber(error.Exception) === sku
  );
};
