import { AutoFocusTextField, ConfirmationDialog, CustomForm, CustomModal, DisplayField } from 'components';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getOrderByOrderName, stopCheck } from 'api/order';
import { useEffect, useState } from 'react';

import { OrderModel } from 'model/Entities';
import { formatDate } from 'utils';
import { stop_check_schema } from 'model/schema';
import { tokens } from 'context/theme.context';
import { useSnackbar } from 'notistack';

type Props = {
  open: boolean;
  setOpen: (openModal: boolean) => void;
  onFlag?: (selectedOrder: OrderModel) => void;
  preSelectedOrder?: OrderModel;
};

const FlagOrderModal: React.FC<Props> = ({ open, setOpen, onFlag, preSelectedOrder }) => {
  const colors = tokens();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderModel>();
  const [openSuccessStopCheck, setOpenSuccessStopCheck] = useState(false);

  const [orderName, setOrderName] = useState<string>('');

  useEffect(() => {
    if (preSelectedOrder) setOrderName(preSelectedOrder.order_name);
    else setOrderName('');
    setSelectedOrder(preSelectedOrder);
  }, [preSelectedOrder]);

  useEffect(() => {
    if (!preSelectedOrder) {
      setSelectedOrder(undefined);
      const timeoutId = setTimeout(() => {
        scanOrder(orderName);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderName]);

  const scanOrder = async (value: string) => {
    if (value) {
      try {
        setSearching(true);
        const { data } = await getOrderByOrderName(value);
        setSelectedOrder(data);
      } catch (error) {
        setSelectedOrder(undefined);
      } finally {
        setSearching(false);
      }
    } else {
      setSelectedOrder(undefined);
    }
  };

  const handleSubmitStopCheck = async (data: any) => {
    if (selectedOrder) {
      try {
        setLoading(true);
        await stopCheck(selectedOrder.id!, data);
        setOpen(false);
        setOpenSuccessStopCheck(true);
        setSelectedOrder(undefined);
        onFlag && onFlag(selectedOrder);
      } catch (error) {
        enqueueSnackbar(`Please try again!`, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleKeyPress: any = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      scanOrder(orderName);
    }
  };

  return (
    <>
      <CustomModal
        open={open}
        setOpen={setOpen}
        header={selectedOrder ? `Stop Check for Order ${selectedOrder?.order_name}` : 'Stop Check Order'}
        width={900}
        onClose={() => setOrderName('')}
      >
        <Box width="100%" display="flex" flexDirection="column" gap="20px">
          {!preSelectedOrder && (
            <Box display="flex" alignItems="center" gap="10px">
              <Typography>Scan/Type Order Number: </Typography>
              <AutoFocusTextField
                value={orderName}
                setValue={setOrderName}
                placeholder="Order No"
                handleKeyPress={handleKeyPress}
              />
              {searching && (
                <Box display="flex" gap="10px">
                  <CircularProgress color="inherit" size="1.3rem" />
                  <Typography>Loading</Typography>
                </Box>
              )}
            </Box>
          )}

          {selectedOrder?.stop_check_flag && (
            <Box mb="10px">
              <Typography variant="h4" fontWeight="bold">
                Order is already in Stop Check.
              </Typography>
              <DisplayField field_name="Remarks" field_value={selectedOrder.stop_check_remarks} />
            </Box>
          )}

          {selectedOrder && (
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap="20px">
              <DisplayField field_name="Order No." field_value={selectedOrder.order_name} />
              <DisplayField field_name="Order Date" field_value={formatDate(selectedOrder.order_date)} />
              <DisplayField field_name="Ship By" field_value={formatDate(selectedOrder.ship_by)} />
              <DisplayField
                field_name="Shipping Country"
                field_value={selectedOrder.shipping_country ? selectedOrder.shipping_country : '-'}
              />
              <DisplayField field_name="Tags" field_value={selectedOrder.tags ? selectedOrder.tags : '-'} />
              <DisplayField
                field_name="SKU"
                flex={1.5}
                field_value={
                  <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px">
                    {selectedOrder.line_items?.map((item: any, index: number) => (
                      <Typography key={index}>{item.sku}</Typography>
                    ))}
                  </Box>
                }
                isComponent
              />
              <DisplayField field_name="Notes" field_value={selectedOrder.note} />
              <DisplayField field_name="Fraudulent Status" field_value={selectedOrder.fraudulent_status} />
            </Box>
          )}

          {!selectedOrder?.stop_check_flag && (
            <CustomForm
              fields={[
                {
                  field_name: 'stop_check_remarks',
                  display_name: 'Remarks',
                  multiline: true,
                  // autoFocus: true,
                  rows: 5,
                  span: 4,
                },
              ]}
              schema={stop_check_schema}
              onSubmit={handleSubmitStopCheck}
              initialValues={{ stop_check_remarks: '' }}
              loading={loading}
              disabled={!selectedOrder}
            />
          )}
        </Box>
      </CustomModal>

      <ConfirmationDialog
        open={openSuccessStopCheck}
        setOpen={setOpenSuccessStopCheck}
        title="Success"
        titleColor={colors.greenAccent}
        message={`Order ${orderName} has been moved to the "Stop Check" tab for further review.`}
        cancelButtonLabel="Okay"
        onClose={() => setOrderName('')}
      />
    </>
  );
};

export default FlagOrderModal;
